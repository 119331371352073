import React from 'react';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../../../../redux/slices';
import { SnackbarContext, FormContext } from '../../../../context';
import { newBudget, updateBudget } from '../../../../services';
import { dateToISO, roundNumber } from '../../../../utils';
import { quotaData } from '../../../../mocks';

export function useSubmit({
  refresh,
  taxes,
  budget,
  config,
}: {
  refresh: () => void;
  taxes: { [key: string]: any };
  budget: { [key: string]: any };
  config: {
    params: boolean;
    user: boolean;
  };
}) {
  const dispatch = useDispatch();
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (spec: { [key: string]: any }) => {
    try {
      dispatch(startLoading());
      if (config.params && !config.user && budget?.es_usd)
        return showErrorSnackbar(
          'El presupuesto seleccionado se encuentra en USD y el usuario no puede operar doble moneda.',
        );
      await axiosPromise({ spec, taxes });
      closeForm();
      budget?.id_presup
        ? showSuccessSnackbar('Presupuesto actualizado correctamente')
        : showSuccessSnackbar('Presupuesto creado correctamente');
      refresh();
    } catch (err: any) {
      let message = 'Unknown Error';
      if (err instanceof Error) message = err.message;
      showErrorSnackbar(message);
    } finally {
      dispatch(stopLoading());
    }
  };
}

function axiosPromise({
  spec,
  taxes,
}: {
  spec: { [key: string]: any };
  taxes: { [key: string]: any };
}) {
  const apiSpec = transformSpec({ spec, taxes });

  return apiSpec.id_presup ? updateBudget(apiSpec) : newBudget(apiSpec);
}

function transformSpec({
  spec,
  taxes,
}: {
  spec: { [key: string]: any };
  taxes: { [key: string]: any };
}) {
  const benefits = spec.benefits_list?.map((b: { [key: string]: any }) => {
    let benefit: { [key: string]: any } = {
      id_prest_presu: b.id_budget ? b.id_budget_benefit : 0,
      id_presup: b.id_budget ? b.id_budget : 0,
      imp_prest: Number(b.amount),
      id_prestacion: b.id_benefit,
      cant: b.quantity,
      pieza: b.tooth_section,
    };

    if (!b.id_prestacion) {
      benefit = {
        ...benefit,
        descripcion: b.description,
      };
    }

    return benefit;
  });
  const balance = roundNumber(
    spec.budget_total_amount - spec.initial_payment.amount,
  ); // Esto se tiene que calcular en el paso 2
  const dateString = dateToISO(spec.date);
  const { tax } = taxes.find(
    (tax: { [key: string]: any }) => tax.id === spec.iva,
  );
  const installments = quotaData?.find(
    (q: { [key: string]: any }) => q.id === spec.installments.quantity,
  )?.text;
  const calculateBalance = () => {
    if (!spec.id_budget) {
      return spec.budget_total_amount;
    } else {
      if (balance === spec.budget_total_amount) {
        return spec.budget_total_amount;
      } else {
        return spec.budget_balance;
      }
    }
  };
  let apiSpec: { [key: string]: any } = {
    id_presup: spec.id_budget,
    id_paciente: spec.id_patient,
    fecha: dateString,
    id_prof: spec.id_professional,
    id_plan: spec.id_plan,
    nro_afil: spec.affiliate_number,
    importe_total: spec.budget_total_amount,
    iva_porc: tax,
    saldo: calculateBalance(),
    tiempo: spec.estimated_time,
    sesiones: spec.sessions,
    pago_inicial: spec.initial_payment.percentage,
    desc_por: Number(spec.discount.percentage_value),
    desc_val: spec.discount.amount_value,
    forma: spec.payment_method,
    cuotas: installments,
    interes_por: spec.interest_rate,
    obs: spec.observation,
    descripcion: spec.description,
    id_usuario: spec.id_user,
    id_sucursal: spec.id_office,
    es_usd: spec.is_usd,
    prest_presup: benefits,
  };

  if (spec.rowversion) {
    apiSpec = {
      ...apiSpec,
      rowversion: spec.rowversion,
    };
  }

  return apiSpec;
}
