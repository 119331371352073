import React from 'react';
import classNames from 'classnames';
import { Copy, Title, Icons } from '../../../../components';
import { useFieldProps } from '../../../../hooks';
import { formatDateToEs, roundNumber } from '../../../../utils';
import { quotaData } from '../../../../mocks';
import { CardStepsT } from '#interfaces/components';

export const CardSummary: React.FC<CardStepsT> = ({
  data: { description, professionalName, medicalInsurances, taxes },
  setTabIndex,
  canEdit,
}) => {
  const {
    field: { value: dateValue },
  } = useFieldProps({ name: 'date' });
  const {
    field: { value: afiliateNumberValue },
  } = useFieldProps({ name: 'affiliate_number' });
  const {
    field: { value: idMedInsValue },
  } = useFieldProps({ name: 'id_medical_insurance' });
  const {
    field: { value: planValue },
  } = useFieldProps({ name: 'id_plan' });
  const {
    field: { value: estimatedTimeValue },
  } = useFieldProps({ name: 'estimated_time' });
  const {
    field: { value: ivaValue },
  } = useFieldProps({ name: 'iva' });
  const {
    field: { value: sessionsValue },
  } = useFieldProps({ name: 'sessions' });
  const {
    field: {
      value: { percentage_value, amount_value },
    },
  } = useFieldProps({ name: 'discount' });
  const {
    field: { value: paymentMethodValue },
  } = useFieldProps({ name: 'payment_method' });
  const {
    field: { value: initialPayPercentageValue },
  } = useFieldProps({ name: 'initial_payment.percentage' });
  const {
    field: { value: initialPayAmountValue },
  } = useFieldProps({ name: 'initial_payment.amount' });
  const {
    field: { value: interestRateValue },
  } = useFieldProps({ name: 'interest_rate' });
  const {
    field: { value: installmentsValue },
  } = useFieldProps({ name: 'installments' });
  const {
    field: { value: observationValue },
  } = useFieldProps({ name: 'observation' });
  const selectMedIns = medicalInsurances?.find(
    (m) => m.id_os === idMedInsValue,
  );
  const selectPlan = selectMedIns?.planes?.find(
    (m: { [key: string]: any }) => m.id_plan === planValue,
  );
  const iva = taxes?.find(
    (t: { [key: string]: any }) => t.id === ivaValue,
  )?.tax;
  const installments = quotaData.find(
    (q) => q.id === installmentsValue.quantity,
  )?.text;

  const cardsData = [
    {
      title: 'Datos Presupuesto',
      className: 'col-span-6 border border-b-slate-300 rounded-md bg-white',
      content: [
        {
          label: 'Descripción:',
          value: description,
        },
        {
          label: 'Fecha:',
          value: formatDateToEs(dateValue),
        },
        {
          label: 'Profesional:',
          value: professionalName?.toUpperCase(),
        },
      ],
      tabIndex: 0,
    },
    {
      title: 'Datos del seguro médico',
      className: 'col-span-6 border border-b-slate-300 rounded-md bg-white',
      content: [
        {
          label: 'O.S:',
          value: selectMedIns?.obra_social || '',
        },
        {
          label: 'Plan:',
          value: selectPlan?.nombre || '',
        },
        {
          label: 'Nro. Afiliado:',
          value: afiliateNumberValue || '-',
        },
      ],
      tabIndex: 1,
    },
    {
      title: 'Datos de pago',
      className: 'col-span-12 border border-b-slate-300 rounded-md bg-white',
      content: [
        {
          label: 'Tiempo estimado:',
          value: estimatedTimeValue,
          styles: 'col-span-3 flex flex-row',
        },
        {
          label: 'Importe descuento:',
          value: amount_value,
          styles: 'col-span-3 flex flex-row',
        },
        {
          label: 'Cant de sesiones:',
          value: sessionsValue,
          styles: 'col-span-3 flex flex-row',
        },
        {
          label: '% de interés:',
          value: interestRateValue,
          styles: 'col-span-3 flex flex-row',
        },
        {
          label: 'Formas de Pago:',
          value: paymentMethodValue,
          styles: 'col-span-3 flex flex-row',
        },
        {
          label: 'IVA:',
          value: iva,
          styles: 'col-span-3 flex flex-row',
        },
        {
          label: 'Pago inicial:',
          value: `${initialPayPercentageValue} %`,
          styles: 'col-span-3 flex flex-row',
        },
        {
          label: 'Cuotas:',
          value: installments,
          styles: 'col-span-3 flex flex-row',
        },
        {
          label: 'Valor inicial:',
          value: `$ ${roundNumber(initialPayAmountValue)}`,
          styles: 'col-span-3 flex flex-row text-green-500',
        },

        {
          label: 'Observaciones:',
          value: observationValue || '-',
          styles: 'col-span-3 flex flex-row truncate',
        },
        {
          label: '% de descuento:',
          value: percentage_value,
          styles: 'col-span-3 flex flex-row',
        },
        {
          label: 'Valor de c/ cuota:',
          value: `$ ${roundNumber(installmentsValue.value)}`,
          styles: 'col-span-3 flex flex-row text-green-500',
        },
      ],
      tabIndex: 2,
    },
  ];

  // Esto se puede atomizar en otro componente
  const renderContent = (content: any[]) => {
    const hasToManyItems = content.length > 3;
    const contentStyles = classNames(
      'p-4 font-bold text-base rounded-md truncate mr-2',
      hasToManyItems ? 'grid grid-cols-12' : 'flex flex-col',
    );

    return (
      <div className={contentStyles}>
        {hasToManyItems
          ? content.map((item, i) => {
              return (
                <div key={i} className={item.styles}>
                  <Copy>{item.label}</Copy>
                  <span className="ml-2">{item.value}</span>
                </div>
              );
            })
          : content.map((item, i) => (
              <div key={i} className="flex flex-row">
                <Copy>{item.label}</Copy>
                <span className="ml-2">{item.value}</span>
              </div>
            ))}
      </div>
    );
  };

  // Para este componente podemos usar la card que ya tenemos
  return cardsData.map((item, index) => (
    <div key={index} className={item.className}>
      <div className="flex items-center border-b border-b-slate-300 p-4">
        <span className="h-6 w-6 mr-2 flex items-center justify-center rounded-full border-2 border-slate-800">
          <p className="text-sm font-bold text-slate-800">{index + 1}</p>
        </span>
        <Title className="text-2xl">{item.title}</Title>
      </div>
      {renderContent(item.content)}
      {canEdit && (
        <p
          className="group flex text-base text-blue-500 justify-between p-4 px-8 border-t border-t-slate-300 hover:cursor-pointer"
          onClick={() => setTabIndex(item.tabIndex)}
        >
          Editar
          <Icons
            type="arrow_right"
            className="h-5 text-sm text-blue-500 transition-transform duration-500 group-hover:translate-x-3"
          />
        </p>
      )}
    </div>
  ));
};
