import React from 'react';
import axios from 'axios';
import * as Tabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import jwt_decode from 'jwt-decode';
import { persistStore } from 'redux-persist';
import { store } from '../redux';
import { defaultApi, version, attendanceOptions, PHONE_CONFIG } from '../mocks';
import translate from '../translate';
import {
  AgendaT,
  CtaCteT,
  MedicalInsurancesT,
  OfficesT,
  PatientT,
  PrestPresupT,
  ProfessionalT,
} from '#interfaces/general-values';
import { PlansT } from '#interfaces/mocks';
import { taxesIndentifications } from '../translate/taxes';
import { UTCDate } from '@date-fns/utc';
import { addHours, isEqual } from 'date-fns';

const days = [
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
  'Domingo',
];
export const identity = (val: any) => val;

export const sanitizeItemMenuString = (string: string) => {
  if (/ó|ò|ô|ö/gm.test(string)) return string.replace(/ó|ò|ô|ö/gm, 'o');
  if (/á|à|â|ä/gm.test(string)) return string.replace(/á|à|â|ä/gm, 'a');
  if (/í|ì|î|ï/gm.test(string)) return string.replace(/í|ì|î|ï/gm, 'i');
  if (/ú|ù|û|ü/gm.test(string)) return string.replace(/ú|ù|û|ü/gm, 'u');
  return string;
};

export const nameFormatter = (name: string) => {
  return name?.toLowerCase().replace(/\b\w/g, function (l) {
    return l.toUpperCase();
  });
};

export const formateDateToString = (date: string) => {
  const d = new Date(date);
  const ye = new Intl.DateTimeFormat('es', { year: 'numeric' }).format(d);
  const mo = new Intl.DateTimeFormat('es', { month: 'short' }).format(d);
  const da = new Intl.DateTimeFormat('es', { day: '2-digit' }).format(d);

  return `${da} de ${mo} de ${ye}`;
};

export const formatMoney = ({
  sign,
  number,
}: {
  sign: string;
  number: number;
}) => `${sign} ${number}`;

const dataMapped = (array: any[]) => array?.some((item) => Array.isArray(item));
const hasObject = (array: any[]) =>
  array?.some((item) => typeof item === 'object');

const getQuantity = (data: any[]) => {
  let quantity;
  hasObject(data) && !!data[0]?.data
    ? (quantity = data?.map((item) =>
        item.data.length > 0 ? item.data.map((v: any) => v?.quantity) : [],
      ))
    : (quantity = data?.map((d) => d?.quantity));

  return { quantity };
};

export const statisticFormatter = ({
  data,
  chart,
  nameTable,
  color,
  label,
}: {
  data: any[];
  chart:
    | 'donut'
    | 'area'
    | 'line'
    | 'bar'
    | 'pie'
    | 'radialBar'
    | 'scatter'
    | 'bubble'
    | 'heatmap'
    | 'candlestick'
    | 'boxPlot'
    | 'radar'
    | 'polarArea'
    | 'rangeBar'
    | 'rangeArea'
    | 'treemap'
    | string;
  nameTable: string;
  label?: string;
  color: string[];
}): {
  series: any[];
  options: { [key: string]: any };
  total: string;
} => {
  const { quantity } = getQuantity(data);
  const categories = (() => {
    let p: string[] | [], donutp: string[] | [];

    p =
      (data && data[0]?.data?.map((d: any) => d.name)) ||
      data?.map((d) => d.name) ||
      [];

    if (chart !== 'donut') return p;

    p = data?.map((d) => d.name) || [];

    donutp = quantity?.map(
      (c, i) => `${p[i]} ${data.find((d) => d.name === p[i])?.percentage}%`,
    );

    return donutp;
  })();

  function makeDataObject() {
    let dataFormatter:
        | {
            series: any[];
            options: { [key: string]: any };
            total?: string;
          }
        | undefined,
      series: any[] | [],
      total: string = '0';
    if (!dataMapped(quantity)) total = quantity?.reduce((a, b) => a + b, 0);
    if (hasObject(data) && data[0].data) {
      series = data?.map((item, i) => {
        return {
          name: item.title,
          data: quantity.length > 0 && quantity[i],
          color: item.color,
        };
      });
    } else {
      series = [
        {
          name: nameTable,
          data: quantity,
        },
      ];
    }

    if (chart === 'bar' || chart === 'line') {
      dataFormatter = {
        series: series,
        options: {
          chart: {
            height: 350,
            borderRadius: 10,
            zoom: {
              enabled: true,
            },
          },
          dataLabels: {
            enabled: false,
            show: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'],
              opacity: 0.5,
            },
          },
          xaxis: {
            labels: {
              rotate: -30,
            },
            categories: categories,
            tickPlacement: 'on',
          },
          colors: color,
          legend: {
            show: false,
          },
        },
      };
    } else if (chart === 'donut') {
      dataFormatter = {
        series: data?.map((item) => item.amount),
        options: {
          labels: categories,
          dataLabels: {
            dropShadow: {
              blur: 3,
              opacity: 0.8,
            },
          },
          fill: {
            type: 'gradient',
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 100,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        },
      };
    }

    return {
      series: dataFormatter?.series || [],
      options: dataFormatter?.options || {},
      total,
    };
  }

  const { series, options, total } = makeDataObject();

  return {
    series,
    options,
    total,
  };
};

export const formatDateGraphic = (dateString: string) => {
  const months = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ];

  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return ` ${day.toString().padStart(2, '0')}-${month}-${year} `;
};

export const getStatisticsPeriod = () => {
  const currentDate = new Date();
  const [firstday] = new Date(
    currentDate.setDate(currentDate.getDate() - currentDate.getDay() - 7),
  )
    .toISOString()
    .split('T');
  const [lastday] = new Date(
    currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 7),
  )
    .toISOString()
    .split('T');
  return [firstday, lastday];
};

export class HandlerError extends Error {
  statusCode: number;
  constructor(message: string, status: number) {
    super(message);
    this.name = 'HandlerError';
    this.statusCode = status || 500;
  }
}
export const axiosInstance = axios.create({
  baseURL: defaultApi,
  // `transformResponse` allows changes to the response data
  // to be made before it is passed to then/catch
  transformResponse: [
    function (data) {
      const jsonData = JSON.parse(data);
      if (jsonData.mensajeError || jsonData.errors) {
        throw new HandlerError(
          jsonData.mensajeError || jsonData.title,
          jsonData.status,
        );
      }

      return jsonData.response;
    },
  ],
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (err) => {
    if (['ERR_NETWORK'].includes(err.code)) {
      persistStore(store).purge();
      window.location.href = '/';
    }

    return Promise.reject(err);
  },
);

export const platform = `W-${version}`;

export const dateToISO = (date: string | Date) => {
  const newDate = new Date(date);

  return newDate.toISOString();
};
export const splitDate = (value: string | Date) => {
  const [date] = dateToISO(value).split('T');
  return date;
};

export const compareDatesRanges = ({
  from,
  to,
  date,
}: {
  from: string;
  to: string;
  date: Date;
}) => {
  if (from && to) {
    return (
      new Date(from).valueOf() < new Date(date).valueOf() &&
      new Date(to).valueOf() > new Date(date).valueOf()
    );
  } else if (!from) {
    return new Date(to).valueOf() > new Date(date).valueOf();
  } else if (!to) {
    return new Date(from).valueOf() < new Date(date).valueOf();
  }
};

export const zeroToNull = (v: number | null | undefined) =>
  v === 0 ? null : v;

export const getDefaultPeriod = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');

  return `${year}${month}`;
};

export function checkToothFaces(toothFaces: { [key: string]: any }) {
  let checkedToothFaces: { [key: string]: any } = {};
  Object.entries(toothFaces).forEach(([key, value]) => {
    if (value) {
      checkedToothFaces[key] = value;
    }
  });

  return checkedToothFaces;
}

export const colorToClassName = (color: string) => {
  const colors: { [key: string]: string } = {
    AZUL: '!bg-blue-500',
    ROJO: '!bg-red-500',
    VERDE: '!bg-green-600',
  };

  return colors[color];
};
function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export const formatDateToEs = (date: string) => {
  const d = new Date(date);
  const adjustedDate = new Date(d.getTime() + d.getTimezoneOffset() * 60000);
  return [
    padTo2Digits(adjustedDate.getDate()),
    padTo2Digits(adjustedDate.getMonth() + 1),
    d.getFullYear(),
  ].join('/');
};

export const numberToCurrency = (number: number) => {
  const transform = number?.toLocaleString('es-AR', {
    style: 'currency',
    currency: 'ARS',
  });

  return transform !== null ? transform : '-';
};

export const todoGridMapper = (todos: any) =>
  todos.map((todo: any) => {
    const {
      cara,
      color,
      fecha,
      diente_sector,
      periodo,
      obs,
      importe_os,
      importe_pac,
      es_usd,
      id_todos,
      id_profNavigation,
      id_prestacionNavigation,
    } = todo;

    const { codigo_prest_os, descripcion, id_planNavigation } =
      id_prestacionNavigation;
    const { nombre: profName } = id_profNavigation;

    const isUsd = () => {
      if (es_usd === null) {
        return false;
      } else {
        return es_usd;
      }
    };

    const [date] = dateToISO(fecha).replace(/-/g, '/').split('T');

    return {
      id: id_todos,
      className: colorToClassName(color),
      Fecha: formatDateToEs(date),
      Profesional: profName,
      'Cod Prest': codigo_prest_os,
      'Cod OS': codigo_prest_os,
      Descripción: descripcion,
      'Obra Social':
        (id_planNavigation && id_planNavigation.id_osNavigation.obra_social) ||
        '',
      Plan: (id_planNavigation && id_planNavigation.nombre) || '',
      'Diente/Sector': diente_sector,
      Cara: cara,
      'Imp OS': numberToCurrency(importe_os),
      'Imp Pac': numberToCurrency(importe_pac),
      es_usd: isUsd(),
      Observaciones: obs,
      Período: periodo,
    };
  });

export function changeFilterText(filterCurrency: boolean | null) {
  if (filterCurrency === true) {
    return 'Moneda U$D';
  }
  if (filterCurrency === false) {
    return 'Moneda Local';
  }
  if (filterCurrency === null) {
    return 'Ambas monedas';
  }
  return '';
}

export function getTranslations(region: string) {
  const taxIndentification =
    taxesIndentifications[region] || taxesIndentifications['all'];
  const translations = translate[region] || translate['all'];
  return { ...translations, taxes: taxIndentification };
}

function setRequestHeader(key: string, value: any) {
  axiosInstance.defaults.headers = {
    ...axiosInstance.defaults.headers,
    [key]: `Bearer ${value}`,
  };
}

export const setAuthHeader = setRequestHeader.bind(null);

export const userPermissionsMapper = (
  userPermissions: any[],
  systemPermissions: any[],
) => {
  const subPermissions = systemPermissions.map((i) => i.opcion);
  const mapped = userPermissions.reduce(function (memo, permission) {
    if (
      permission.id_procesoNavigation.item_sistema ===
      permission.id_procesoNavigation.opcion
    ) {
      memo[permission.id_procesoNavigation.item_sistema] = true;
    }
    if (subPermissions.includes(permission.id_procesoNavigation.opcion)) {
      memo[permission.id_procesoNavigation.opcion] = true;
    }
    return memo;
  }, {});

  return mapped;
};

async function setBaseUrlFn(baseURL: string) {
  if (process.env.NODE_ENV === 'production' && baseURL) {
    axiosInstance.defaults.baseURL = `${baseURL}/api`;
  } else if (process.env.NODE_ENV === 'development') {
    axiosInstance.defaults.baseURL = `${defaultApi}/dev`;
  } else {
    axiosInstance.defaults.baseURL = `${defaultApi}/api`;
  }
}
export const setBaseUrl = setBaseUrlFn.bind(null);

export function parseOdontogramType(type: string) {
  return type === 'per' ? 'odontogramaper' : 'odontogramapri';
}

export const makeBudgetResumeSections = ({
  budgetTotalAmount,
  professionalName,
  idBudget,
  budgetBalance,
}: {
  budgetTotalAmount: number;
  professionalName: string;
  idBudget: string;
  budgetBalance: number;
}) => {
  const movementsType =
    budgetTotalAmount === budgetBalance && budgetBalance !== 0
      ? 'Sin movimiento'
      : budgetBalance < budgetTotalAmount
      ? 'Con movimiento'
      : 'Saldado';
  const stylesMovements = {
    'Sin movimiento': 'bg-slate-100 !text-slate-500 px-2 py-px',
    'Con movimiento': 'bg-green-100 !text-green-500 px-2 py-px',
    Saldado: 'bg-yellow-100 !text-yellow-500 px-2 py-px',
  };
  const sectionCss =
    'font-bold tracking-wide leading-5 text-slate-900 text-xl rounded';
  const tableResult = [
    {
      title: 'Profesional',
      value: professionalName?.toUpperCase(),
      styles: sectionCss,
    },
    {
      title: 'Valor total',
      value: budgetTotalAmount ? `$${budgetTotalAmount}` : '-',
      styles: sectionCss,
    },
  ];
  if (!!idBudget) {
    tableResult.push(
      {
        title: 'Saldo',
        value: `$${budgetBalance}`,
        styles: sectionCss,
      },
      {
        title: 'Estado',
        value: movementsType,
        styles: classNames(sectionCss, stylesMovements[movementsType]),
      },
    );
  }

  return tableResult;
};

export const roundNumber = (value: number) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

export const orderBenefitsByCode = (benefits: { [key: string]: any }[]) =>
  benefits?.sort(({ codigo_prest_os: a }, { codigo_prest_os: b }) => {
    const splitter = (str: string) => str.split('.', 1)[0];

    return Number(splitter(a)) - Number(splitter(b));
  });

export const BenefitsLoadGridMapper = (benefits: { [key: string]: any }[]) =>
  benefits.map((benefit: { [key: string]: string | number }) => {
    const {
      amount,
      benefit_code,
      description,
      id_benefit,
      id_budget,
      id_budget_benefit,
      is_manual,
      needs_thoot,
      quantity,
      thoot_face,
      tooth_section,
      total_amount,
    } = benefit;

    return {
      is_manual,
      id_benefit,
      id_budget: id_budget,
      id: id_budget_benefit,
      benefit_code,
      description,
      amount: `$${amount}`,
      quantity,
      tooth_section,
      total_amount: `$${total_amount}`,
      needs_thoot,
      thoot_face,
    };
  });

export const budgetBenefitListTotalValue = (
  benefits: { [key: string]: any }[],
) => {
  let total = 0;
  benefits.forEach((benefit: { [key: string]: number }) => {
    total += benefit.amount * benefit.quantity;
  });

  return roundNumber(total);
};

export const updateTotalAmount = ({
  totalValueBenefits,
  IVAAmount,
  percentage,
  percentage_value,
  amount_value,
  interestRateValue,
}: {
  totalValueBenefits: number;
  IVAAmount: number;
  percentage: string;
  percentage_value: number;
  amount_value: number;
  interestRateValue: number;
}) => {
  const totalWhitIVA = roundNumber(totalValueBenefits * (IVAAmount / 100 + 1));
  const totalWithDiscounts = percentage
    ? totalWhitIVA - totalWhitIVA * (percentage_value / 100)
    : totalWhitIVA - amount_value;
  const totalWithInterest = roundNumber(
    totalWithDiscounts * (interestRateValue / 100 + 1),
  );

  return totalWithInterest;
};

export const calculateBudgetNewBalance = ({
  total,
  previousTotal,
  balance,
}: {
  total: number;
  previousTotal: number;
  balance: number;
}) => {
  let newBalance;
  let totalDiff = 0;

  if (total !== previousTotal) {
    totalDiff = total - previousTotal;
  }
  if (total === balance && !totalDiff) return total;
  newBalance = balance + totalDiff;

  return newBalance;
};

export const getWeek = () => {
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);
  return {
    today: today.toISOString().split('T')[0],
    sevenDaysAgo: sevenDaysAgo.toISOString().split('T')[0],
  };
};

export const budgetPaymentsGridMapper = (payments: { [key: string]: any }[]) =>
  payments.map((payment) => {
    const {
      id_pago,
      id_presup,
      fecha,
      forma,
      concepto,
      id_profNavigation,
      tipo,
      comprobante,
      numero,
      haber,
      iva,
    } = payment;

    const [date] = dateToISO(fecha).replace(/-/g, '/').split('T');
    const professional = id_profNavigation.nombre;

    return {
      id_payment: id_pago,
      id_budget: id_presup,
      date: formatDateToEs(date),
      description: concepto,
      professional,
      payment_document: comprobante,
      payment_type: tipo,
      number: numero,
      method: forma,
      amount_paid: haber,
      tax: iva,
    };
  });

export const tableBudgetsMapper = (budgets: { [key: string]: any }[]) =>
  budgets.map((budget) => {
    const {
      id_presup,
      importe_total,
      saldo,
      fecha,
      id_planNavigation: { id_osNavigation },
      id_profNavigation: { nombre: profesional },
      descripcion,
      es_usd,
    } = budget;

    const [date] = dateToISO(fecha).replace(/-/g, '/').split('T');
    const obraSocial = id_osNavigation && id_osNavigation.obra_social;
    const STATUS = {
      payed: {
        text: 'Saldado',
        style: 'bg-yellow-50 text-yellow-600 border-yellow-600/10',
      },
      movement: {
        text: 'En movimiento',
        style: 'bg-green-50 text-green-600 border-green-600/10',
      },
      not_movement: {
        text: 'Sin movimiento',
        style: 'bg-slate-50 text-slate-600 border-slate-600/10',
      },
    } as const;

    return {
      id_budget: id_presup,
      date: formatDateToEs(date),
      professional: profesional,
      description: descripcion,
      medical_insurance: obraSocial,
      total: numberToCurrency(importe_total),
      balance: numberToCurrency(saldo),
      is_usd: es_usd || false,
      status:
        saldo < importe_total && saldo !== 0
          ? STATUS['movement']
          : importe_total !== 0 && saldo === 0
          ? STATUS['payed']
          : STATUS['not_movement'],
    };
  });

export const renderTabs = (
  tabs: { [key: string]: any },
  customClass?: string,
) => {
  return tabs.map((tab: { [key: string]: any }, i: number) => {
    const className = classNames(
      'hover:cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-bold text-slate-400 transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-white data-[state=active]:text-blue-500 data-[state=active]:shadow-sm',
      customClass,
    );

    return (
      <Tabs.Trigger key={i} className={className} value={tab.value}>
        {tab.text}
      </Tabs.Trigger>
    );
  });
};

export const usersGridMapper = (users: { [key: string]: any }[]) =>
  users.map((user: { [key: string]: any }) => {
    const {
      clave,
      id_prof,
      id_sucursal,
      id_usuario,
      es_odontologo,
      es_odontologo_agenda,
      es_odontologo_datos,
      es_odontologo_guardia,
      es_supervisor,
      habilitado,
      nombre,
      opcion,
      permisos,
      rowversion,
      usuario,
      doble_moneda,
      recetas_tyc,
    } = user;
    return {
      id: id_usuario,
      id_office: id_sucursal,
      id_prof,
      supervisor: es_supervisor,
      dentist: es_odontologo,
      dentist_schedule: es_odontologo_agenda,
      dentist_guard: es_odontologo_guardia,
      dentist_data: es_odontologo_datos,
      enabled: habilitado,
      password: clave,
      name: nombre,
      option: opcion,
      permissions: permisos,
      user: usuario,
      use_usd: doble_moneda,
      rowversion,
      recetas_tyc,
    };
  });

export const abrencesGridMapper = (absences: { [key: string]: any }) =>
  absences.map((absence: { [key: string]: any }) => {
    const { id_feriado, id_prof, desde, hasta, dia, opcion, rowversion } =
      absence;

    return {
      id: id_feriado,
      id_prof,
      className: 'absences',
      day_num: dia,
      day: days[dia - 1] ?? 'Todos',
      from: desde,
      to: hasta,
      format_date_from: formateDateToString(desde),
      format_date_to: formateDateToString(hasta),
      option: opcion,
      rowversion,
    };
  });

export function isMajorOrMinor(str: string) {
  let value;
  if (str.includes('>')) {
    value = 'major';
  } else if (str.includes('<')) {
    value = 'minor';
  } else {
    value = 'none';
  }

  return value;
}

export function fromatAge(ageText: string, age: string) {
  if (ageText === 'none') return '0';
  if (ageText === 'major') return `> ${age}`;
  if (ageText === 'minor') return `< ${age}`;
}

export const benefitsGridMapper = (
  benefits: { [key: string]: any }[],
  groups: { [key: string]: any },
) =>
  benefits?.map((benefit) => {
    const {
      id_plan,
      id_prestacion,
      id_prestacion_a_realizar,
      id_grupo,
      id_especialidad,
      codigo_prest_os,
      codigo_prest_os_liq,
      descripcion,
      importe_os,
      importe_pac,
      diente,
      pide_cara,
      es_odontograma,
      interno,
      inhabilitada,
      dibujo,
      unidad,
      cantidad,
      por_paciente,
      por_pieza_sector,
      edad,
      es_usd,
      rowversion,
    } = benefit;
    const group = groups?.find(
      (g: { [key: string]: any }) => g.id_grupo === id_grupo,
    );

    const amount = (!cantidad || cantidad === 999) && 0;

    return {
      id_plan,
      id_benefit: id_prestacion,
      id_benefit_to_do: id_prestacion_a_realizar,
      id_group: id_grupo,
      id_specialty: id_especialidad,
      code_medical_insurance: codigo_prest_os,
      code_medical_insurance_bill: codigo_prest_os_liq,
      description: descripcion,
      billing_medical_insurance: importe_os,
      billing_patient: importe_pac,
      tooth: diente,
      tooth_face: pide_cara,
      catastral: es_odontograma,
      intern: interno,
      enabled: !inhabilitada,
      draw: dibujo,
      unit: unidad,
      group_belongs: !!group?.descripcion || false,
      group_belongs_name: group?.descripcion || null,
      amount: amount || 0,
      amount_patient: por_paciente,
      amount_unit: por_pieza_sector,
      is_usd: es_usd || false,
      age: edad,
      rowversion,
    };
  });

export const groupsGridMapper = (groups: { [key: string]: any }[]) =>
  groups.map((group) => {
    const { cod_grupo, id_grupo, id_os, descripcion, rowversion } = group;

    return {
      id: id_grupo,
      id_os,
      code: cod_grupo,
      description: descripcion,
      rowversion,
    };
  });

export const holidaysGridMapper = (holidays: { [key: string]: any }[]) =>
  holidays.map((holiday) => {
    const { id_feriado, id_prof, desde, hasta, opcion, rowversion } = holiday;

    return {
      id: id_feriado,
      id_prof,
      from: desde,
      to: hasta,
      format_date_from: formateDateToString(desde),
      format_date_to: formateDateToString(hasta),
      option: opcion,
      rowversion,
    };
  });

export const officesGridMapper = (offices: { [key: string]: any }[]) =>
  offices.map((office) => {
    const {
      descripcion,
      id_sucursal,
      id_sucursal_nueva,
      habilitada,
      opcion,
      fact_empresa,
      fact_pas,
      fact_usu,
      fecha_cierre_caja,
      pventa,
      saldo_caja,
      saldo_caja_usd,
      sucursal,
      rowversion,
    } = office;

    return {
      id: id_sucursal,
      id_new_office: id_sucursal_nueva,
      enabled: habilitada,
      office: sucursal,
      description: descripcion,
      bill_company: fact_empresa,
      bill_user: fact_usu,
      bill_pass: fact_pas,
      bill_point: pventa,
      cash_closure: fecha_cierre_caja,
      cash_closure_show: fecha_cierre_caja
        ? formateDateToString(fecha_cierre_caja)
        : null,
      cash_balance: saldo_caja,
      cash_balance_usd: saldo_caja_usd,
      option: opcion,
      rowversion,
    };
  });

export const plansGridMapper = (plans: { [key: string]: any }[]) =>
  plans.map((plan) => {
    const { id_os, id_plan, codigo_plan, inhabilitada, nombre, rowversion } =
      plan;

    return {
      id: id_plan,
      id_os,
      code: codigo_plan,
      name: nombre,
      enabled: !inhabilitada,
      rowversion,
    };
  });

export const templatesMapper = (templates: { [key: string]: any }) => {
  const mappedTemplates: { [key: string]: any } = {
    turns: [],
    turns_office: [],
    birthday: [],
  };
  templates.forEach((template: { [key: string]: any }) => {
    template.name.includes('turnos') &&
      !template.name.includes('sucur') &&
      mappedTemplates.turns.push(template);
    template.name.includes('turnos') &&
      template.name.includes('sucur') &&
      mappedTemplates.turns_office.push(template);
    template.name.includes('cumple') && mappedTemplates.birthday.push(template);
  });
  return mappedTemplates;
};

export const sistemPermissionsMapper = (arr: { [key: string]: any }[]) => {
  const mapped = arr.reduce(function (memo, permission) {
    const perm = permission.item_sistema.toLowerCase().replace(' ', '');
    if (!memo[perm]) {
      memo[perm] = [];
      memo[perm].push(permission);
    } else if (memo[perm]) {
      memo[perm].push(permission);
    }
    return memo;
  }, {});

  // Here we do this to remove unused permissions
  ['backup', 'xxx'].forEach(
    (p) => mapped.hasOwnProperty(p) && delete mapped[p],
  );
  return mapped;
};

export const timetableGridMapper = (schedule: { [key: string]: any }) =>
  schedule.map((time: { [key: string]: any }) => {
    const {
      id_horario,
      id_prof,
      id_sucursal,
      desde,
      hasta,
      dia,
      id_especialidad,
      intervalo,
      rowversion,
      fecha_desde,
      fecha_hasta,
    } = time;

    const fomattedDate = (date: string) => {
      if (date) return formateDateToString(date);
      return 'Fecha abierta';
    };

    return {
      id: id_horario,
      id_prof,
      id_sucursal,
      id_especialidad,
      day_num: dia,
      className: 'timetable',
      from: desde,
      to: hasta,
      format_date_from: fomattedDate(fecha_desde),
      format_date_to: fomattedDate(fecha_hasta),
      date_from: fecha_desde,
      date_to: fecha_hasta,
      day: days[dia - 1],
      interval: intervalo,
      rowversion,
    };
  });

export const auditorGridMapper = (data: { [key: string]: any }) =>
  data.map((service: { [key: string]: any }) => {
    const {
      date,
      patient,
      affiliateNumber,
      liquidationCode,
      description,
      toothSector,
      faces,
      amount,
    } = service;

    return {
      date: new Date(date).toLocaleDateString(),
      patient,
      affiliate: affiliateNumber,
      med_ins_code: liquidationCode,
      description,
      thoot_section: toothSector,
      thoot_face: faces,
      med_ins_amount: amount !== null ? `$ ${amount}` : '-',
    };
  });

export const installmentsMapper = (card: { [key: string]: any }) => {
  const { tarjetas_cuotas } = card;
  return tarjetas_cuotas.map((cuota: { [key: string]: any }) => {
    return {
      id: cuota.id_tar_cuo,
      text: cuota.nro_cuota,
      interest: cuota.interes,
    };
  });
};

export const ccFormatNumber = (str: string) => {
  var v = str.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  var matches = v.match(/\d{4,16}/g);
  var match = (matches && matches[0]) || '';
  var parts = [];
  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }
  if (parts.length) {
    return parts.join(' ');
  } else {
    return str;
  }
};

export const tablePaymentsMapper = (pagos: CtaCteT[]) =>
  pagos.map((pago) => {
    const {
      fecha,
      id_pago,
      id_prof,
      id_todos,
      id_presup,
      profesional,
      concepto,
      comprobante,
      tipo,
      numero,
      forma,
      debe,
      haber,
      saldo,
      es_usd,
      obs,
      anulado,
      usuario,
    } = pago;

    const [date] = dateToISO(fecha).replace(/-/g, '/').split('T');
    const status: { [key: string]: { [key: string]: string } } = {
      has_benefit: {
        text: 'Prestación',
        style: 'bg-green-50 text-green-600 border-green-600/10',
      },
      payment: {
        text: 'Pago',
        style: 'bg-slate-50 text-slate-600 border-slate-600/10',
      },
      canceled: {
        text: 'Pago anulado',
        style: 'bg-red-50 text-red-600 border-red-600/10',
      },
    };

    return {
      id_budget: id_presup,
      id_payment: id_pago,
      id_professional: id_prof,
      id_benefit: id_todos,
      date,
      date_es: formatDateToEs(date),
      professional: profesional,
      concept: concepto,
      voucher: comprobante,
      type: tipo,
      number: numero,
      method: forma,
      debit: debe,
      assets: haber,
      balance: saldo,
      observations: obs,
      status: id_todos
        ? status['has_benefit']
        : anulado
        ? status['canceled']
        : status['payment'],
      is_usd: es_usd || false,
      canceled: anulado,
      modified_by: usuario,
    };
  });

export const remindersGridMapper = (schedule: AgendaT[], profId: number) => {
  const scheduleFiltered = schedule.filter((obj: any) => {
    if (profId && profId !== -1) {
      return obj.id_agenda !== -1 && obj.id_prof === profId;
    }
    return obj.id_agenda !== -1;
  });

  return scheduleFiltered.map((turn: any) => {
    const {
      id_agenda,
      id_paciente,
      id_pacienteNavigation,
      id_sucursal,
      id_sucursalNavigation,
      paciente,
      id_profNavigation: { id_prof, abreviatura, nombre: profesional },
      hora,
      telefono,
      rowversion,
      fecha,
    } = turn;

    const officeName = id_sucursalNavigation
      ? id_sucursalNavigation.descripcion
      : '';

    const patientName = id_pacienteNavigation
      ? id_pacienteNavigation.ape_nom
      : paciente;

    return {
      id_schedule: id_agenda,
      id_patient: id_paciente,
      id_office: id_sucursal,
      office: officeName,
      phone: id_pacienteNavigation?.tel_celu || telefono,
      patient: patientName,
      date: fecha,
      id_prof,
      professional: profesional,
      abbreviation: abreviatura,
      hour: hora,
      reminder_response: turn?.reminder_response || null,
      rowversion,
    };
  });
};

export function addDefaultProf(profList: ProfessionalT[]) {
  const newProf = {
    id_prof: -1,
    nombre: 'TODOS',
    habilitado: true,
    id_especialidad1: null,
    id_especialidad2: null,
    prof_horarios: [],
  };
  const newProfs: any[] = [newProf, ...profList];
  return newProfs;
}

export function messagesListReducer(arr: any[]) {
  let newArr: any[] = [];
  arr.forEach((e) => {
    if (newArr.find((j) => j.id_agenda === e.id_agenda)) {
      let [obj] = newArr.filter((j) => j.id_agenda === e.id_agenda);
      newArr = newArr.filter((j) => j.id_agenda !== e.id_agenda);
      newArr.push({ ...obj, mensaje: [...obj.mensaje, e.mensaje] });
    } else {
      newArr.push({ ...e, mensaje: [e.mensaje] });
    }
  });
  return newArr;
}

export const tableAppointmentsMapper = (appointments: any[]) =>
  appointments.map((appointment) => {
    const {
      fecha,
      id_agenda,
      hora,
      obs,
      asistencia,
      fecha_dado,
      id_profNavigation: { id_prof, nombre: profesional },
    } = appointment;

    return {
      id_schedule: id_agenda,
      id_professional: id_prof,
      date: new Date(fecha).toLocaleDateString(),
      time: hora,
      observations: obs,
      attendance: attendanceOptions.filter((a) => a.id === asistencia)[0],
      given_date: fecha_dado && new Date(fecha_dado).toLocaleDateString(),
      professional: profesional,
    };
  });

export const validationNumberPhone = (number: string) => {
  const regex = /[0-9]{8}/;

  let numberFormat = number
    .replace(/ /g, '')
    .replace(/-/g, '')
    .replace(/\./g, '')
    .replace(/_/g, '')
    .replace(/\+/g, '')
    .replace(/,/g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '');
  return regex.test(numberFormat) && numberFormat;
};

export const groupByCategory = (groupCategory: any) => {
  const groups = [
    {
      category: 'day',
      value: 'Día',
    },
    {
      category: 'month',
      value: 'Mes',
    },
    {
      category: 'year',
      value: 'Año',
    },
    {
      category: 'medical_insurance',
      value: true,
    },
    {
      category: 'professional',
      value: false,
    },
  ];
  let value;
  groups.forEach((g) => {
    if (groupCategory[g.category]) return (value = g.value);
  });

  return value;
};

export const attendanceGridMapper = (
  data: {
    fecha: string;
    hora: string;
    id_paciente: number;
    paciente: string;
    profesional: string;
    telefono: string | null;
  }[],
) =>
  data.map((attendance) => {
    const { fecha, hora, paciente, profesional, telefono } = attendance;

    return {
      fecha: new Date(fecha).toLocaleDateString(),
      hora,
      paciente,
      telefono: telefono || '-',
      profesional,
    };
  });
export const laboratoriesOrdersGridMapper = (
  data: {
    email: string;
    email_laboratorio: string;
    fecha: string;
    id_paciente: number;
    paciente: string;
    profesional: string;
    laboratorio: string;
    hora: string | null;
    telefono: string | null;
    telefono_laboratorio: string | null;
  }[],
) =>
  data.map((lab) => {
    const {
      fecha,
      paciente,
      profesional,
      hora,
      telefono,
      email,
      email_laboratorio,
      id_paciente,
      laboratorio,
      telefono_laboratorio,
    } = lab;

    return {
      id_paciente,
      fecha: new Date(fecha).toLocaleDateString(),
      hora,
      paciente,
      laboratorio,
      email: email || '-',
      email_laboratorio: email_laboratorio || '-',
      telefono: telefono || '-',
      telefono_laboratorio: telefono_laboratorio || '-',
      profesional,
    };
  });

export function messagesFormatter(messages: any[]) {
  return messages.map((m) => {
    return {
      hora: m.hour,
      idAgenda: m.id_schedule,
      idPaciente: m.id_patient,
      idSucursal: m.id_office,
      nroTel: m.phone,
      paciente: m.patient,
      profesional: m.professional,
      abreviatura: m.abbreviation,
      sucursal: m.office,
    };
  });
}

export function prepareMessageToProcess(message: any) {
  return {
    idagendaprocesar: message.id_agenda,
    listawhatsapps: [
      {
        hora: message.horaTurno,
        idagenda: message.id_agenda,
        idpaciente: message.id_paciente,
        idsucursal: message.id_sucursal,
        nrotel: message.nro_tel,
        paciente: message.pacNombre,
        profesional: message.profesional,
        sucursal: message.sucursal,
      },
    ],
  };
}

export const getPresetNames = (
  preset: { id: string; label: string }[],
  name: string,
) => {
  return preset?.find((p) => p.label === name)?.id;
};

export const formatTime = (time: number) => (time < 10 ? `0${time}` : time);

export const validateDateOverturn = (overturn: any, schedule: AgendaT[]) => {
  const scheduleHours = schedule.map((obj) => obj.hora);

  return scheduleHours.includes(overturn.hora);
};
export const tableBenefitsMapper = (todos: any) =>
  todos.map((todo: any) => {
    const {
      cara,
      color,
      fecha,
      diente_sector,
      obs,
      importe_os,
      importe_pac,
      es_usd,
      id_todos,
      id_profNavigation,
      id_prestacionNavigation,
    } = todo;

    const {
      codigo_prest_os,
      codigo_prest_os_liq,
      descripcion,
      id_planNavigation,
    } = id_prestacionNavigation;
    const { nombre: profName } = id_profNavigation;
    const [date] = dateToISO(fecha).replace(/-/g, '/').split('T');
    const status: { [key: string]: { [key: string]: string } } = {
      ROJO: {
        text: 'Prestación anterior',
        style: 'bg-red-50 text-red-600 border-red-600/10',
      },
      AZUL: {
        text: 'Prestación a realizar',
        style: 'bg-blue-50 text-blue-600 border-blue-600/10',
      },
      VERDE: {
        text: 'Prestación realizada',
        style: 'bg-green-50 text-green-600 border-green-600/10',
      },
    };

    return {
      id: id_todos,
      date: formatDateToEs(date),
      state: {
        style: status[color]?.style || '',
        status: status[color]?.text || '',
      },
      professional: profName,
      benefit_code: codigo_prest_os,
      medical_insurance_code: codigo_prest_os_liq,
      description: descripcion,
      medical_insurance:
        (id_planNavigation && id_planNavigation.id_osNavigation.obra_social) ||
        '',
      medical_insurance_plan:
        (id_planNavigation && id_planNavigation.nombre) || '',
      thoot: diente_sector,
      thoot_face: cara,
      amouht_medical_insurance: numberToCurrency(importe_os),
      amount_patient: numberToCurrency(importe_pac),
      is_usd: es_usd || false,
      observations: obs,
    };
  });

export const tablePatientChMapper = (patientCh: { [key: string]: any }) =>
  patientCh.map((clinic_record: { [key: string]: any }) => {
    const {
      fecha,
      hist_cli,
      id_his_cli,
      id_paciente,
      rowversion,
      profesional,
      id_prof,
      id_sucursal,
    } = clinic_record;
    const profesionalName = profesional
      ? `${profesional.abreviatura} ${profesional.nombre}`
      : '';

    return {
      id_clinic_record: id_his_cli,
      id_patient: id_paciente,
      id_office: id_sucursal,
      id_professional: id_prof,
      date: fecha,
      text: hist_cli,
      rowversion,
      professional: profesionalName,
    };
  });
export const tablePatientRecipesMapper = (data: any) =>
  data?.recipes?.map((recipe: { [key: string]: any }) => {
    const {
      prescriptionId,
      date,
      fileLink,
      verificationLink,
      patientId,
      medications,
      diagnosis,
      medicalInsuranceId,
      professional,
    } = recipe;
    const medicalInsurance = data?.medicalInsurance?.find(
      (m: any) => m.id_os === medicalInsuranceId,
    ); //revisar ids

    return {
      date_recipe: date?.split('T')[0],
      medicalInsurance: medicalInsurance,
      professional: professional?.nombre || '-',
      diagnosis,
      medications: JSON.parse(medications),
      fileLink,
      verificationLink,
      patientId,
      prescriptionId,
    };
  });
export const tablePatientResultMapper = (patients: PatientT[]) =>
  patients.map((patient) => {
    const {
      id_paciente,
      nro_afil,
      documento,
      domicilio,
      ape_nom,
      hc_nro,
      id_planNavigation: { nombre, id_osNavigation, id_os },
      f_nacimiento,
    } = patient;

    return {
      id_patient: id_paciente,
      id_medical_insurance: id_os,
      affiliate_number: nro_afil,
      document: documento,
      address: domicilio,
      name: ape_nom,
      hc_number: hc_nro,
      medical_insurance_name: id_osNavigation?.obra_social,
      medical_insurance_plan: nombre,
      birth_date: f_nacimiento,
    };
  });

export const setArrivalHour = (assistance: string | null) => {
  const hour = new Date().getHours();
  const minutes = new Date().getMinutes();
  if (assistance === 'PRE')
    return `${hour}:${minutes > 10 ? minutes : `0${minutes}`}`;
  return null;
};

export const initialStatisticsData = {
  DateFrom: getWeek().sevenDaysAgo,
  DateTo: getWeek().today,
  GroupDateBy: 'Day',
  GroupByMedicalInsurances: true,
  MedicalInsurancesDisabled: false,
  ProfessionalDisabled: false,
  ProfessionalId: null,
  MedicalInsuranceId: null,
  SubsidiaryId: null,
  BenefitCode: null,
};

export const initialDatesFinances = {
  DateFrom: getWeek().sevenDaysAgo,
  DateTo: getWeek().today,
  ComparationDateFrom: null,
  ComparationDateTo: null,
  ProfessionalId: null,
  MedicalInsuranceId: null,
  OfficeId: null,
  CalculateInUSD: false,
};

export const tableScheduleMapper = (schedule: any[]) =>
  schedule.map((appointment) => {
    const {
      id_agenda,
      id_paciente,
      id_prof,
      id_sucursal,
      id_usuario,
      rowversion,
      fecha,
      es_sobreturno,
      no_dar,
      hora,
      paciente,
      obs,
      asistencia,
      obra_social,
      plan_nombre,
      nro_afil,
      hora_llegada,
      telefono,
      fecha_dado,
      id_pacienteNavigation,
      id_usuarioNavigation,
      id_profNavigation,
    } = appointment;

    const patientName = id_pacienteNavigation
      ? id_pacienteNavigation.ape_nom
      : paciente;

    const medicalInsurance =
      id_pacienteNavigation?.id_planNavigation?.id_osNavigation?.obra_social;
    const medicalInsurancePlan =
      id_pacienteNavigation?.id_planNavigation?.nombre;
    const profesional =
      id_profNavigation &&
      `${id_profNavigation.abreviatura} ${id_profNavigation.nombre}`;

    const turnType = () => {
      if (no_dar && es_sobreturno)
        return {
          type: 'overturn',
          status: 'canceled',
        };
      if (no_dar)
        return {
          type: 'turn',
          status: 'canceled',
        };
      if (es_sobreturno) {
        if (paciente)
          return {
            type: 'overturn',
            status: 'patient_first_time',
          };
        if (id_paciente)
          return {
            type: 'overturn',
            status: 'patient_exist',
          };
        return {
          type: 'overturn',
          status: 'free',
        };
      }
      if (id_paciente || paciente) {
        if (paciente)
          return {
            type: 'turn',
            status: 'patient_first_time',
          };
        return {
          type: 'turn',
          status: 'patient_exist',
        };
      }
      return {
        type: 'turn',
        status: 'free',
      };
    };

    return {
      id_appointment: id_agenda,
      id_patient: id_paciente,
      id_professional: id_prof,
      id_office: id_sucursal,
      id_user: id_usuario,
      rowversion,
      is_overturn: es_sobreturno,
      dont_give: no_dar,
      time: hora,
      date: fecha,
      patient_name: no_dar ? 'NO CITAR ESTE TURNO' : patientName,
      patient_phone: id_pacienteNavigation
        ? id_pacienteNavigation.tel_celu
        : telefono,
      clinic_history_number: id_pacienteNavigation?.hc_nro ?? '',
      observation: obs,
      attendance: asistencia,
      professional_name: profesional,
      mod_by: id_usuarioNavigation?.nombre ?? '',
      eta: hora_llegada ?? '',
      medical_insurance: {
        name: id_pacienteNavigation ? medicalInsurance : obra_social,
        plan: id_pacienteNavigation ? medicalInsurancePlan : plan_nombre,
        affiliate_number: id_pacienteNavigation
          ? id_pacienteNavigation.nro_afil
          : nro_afil,
      },
      given_date: fecha_dado,
      appointment_type: turnType(),
    };
  });

export const ValidatorDateTurn = ({
  date,
  time,
}: {
  date: string;
  time: string;
}) => {
  const year = Number(date.split('-')[0]);
  const month = Number(date.split('-')[1]) - 1;
  const day = Number(date.split('-')[2].split('T')[0]);
  const hour = Number(time.split(':')[0]);
  const minutes = Number(time.split(':')[1]);
  return new Date(year, month, day, hour, minutes) > new Date();
};

export const scheduleGridMapper = (schedule: any[]) =>
  schedule.map((turn) => {
    const {
      id_agenda,
      id_paciente,
      id_prof,
      id_sucursal,
      id_usuario,
      rowversion,
      fecha,
      es_sobreturno,
      no_dar,
      hora,
      paciente,
      obs,
      asistencia,
      obra_social,
      plan_nombre,
      nro_afil,
      hora_llegada,
      telefono,
      fecha_dado,
      id_pacienteNavigation,
      id_usuarioNavigation,
      id_profNavigation,
    } = turn;

    const patientName = id_pacienteNavigation
      ? id_pacienteNavigation.ape_nom
      : paciente;
    const medicalInsurance =
      id_pacienteNavigation?.id_planNavigation?.id_osNavigation.obra_social ??
      obra_social;
    const medicalInsurancePlan =
      id_pacienteNavigation?.id_planNavigation?.nombre ?? plan_nombre;
    const profesional = id_profNavigation
      ? `${id_profNavigation.abreviatura} ${id_profNavigation.nombre}`
      : '';
    const turnType = () => {
      if (no_dar && es_sobreturno) return 'canceled overturn';
      if (no_dar) return 'canceled';
      if (es_sobreturno) {
        if (paciente) return 'given_turn first_time overturn';
        if (id_paciente) return 'given_turn exist_patient overturn';
        return 'turn overturn';
      }
      if (id_paciente || paciente) {
        if (paciente) return 'given_turn first_time';
        return 'given_turn exist_patient';
      }
      return 'turn';
    };

    return {
      id_agenda,
      id_paciente,
      id_prof,
      id_sucursal,
      id_usuario,
      rowversion,
      es_sobreturno,
      no_dar,
      hora,
      fecha,
      nro_hc: id_pacienteNavigation?.hc_nro ?? '',
      paciente: no_dar ? 'NO CITAR ESTE TURNO' : patientName,
      obs,
      asistencia,
      profesional,
      mod_by: id_usuarioNavigation?.nombre ?? '',
      hora_llegada: hora_llegada ?? '',
      obra_social: medicalInsurance,
      plan_nombre: medicalInsurancePlan,
      nro_afil: id_pacienteNavigation
        ? id_pacienteNavigation.nro_afil
        : nro_afil,
      telefono: id_pacienteNavigation
        ? id_pacienteNavigation.tel_celu
        : telefono,
      fecha_dado: fecha_dado && new Date(fecha_dado).toLocaleDateString(),
      className: `e-row e-altrow ${turnType()}`,
    };
  });

export const keysFilter = (
  titlesGrids: [
    {
      title: string;
      key: string;
      className: string;
    },
  ],
) =>
  titlesGrids.reduce((acc: any, item) => {
    return typeof item === 'object' && item.key ? [...acc, item.key] : acc;
  }, []);

export const dropdownDataFilters = {
  obraSocial: (data: MedicalInsurancesT[]) =>
    data.map((item) => {
      return { data: item.obra_social, id: item.id_os };
    }),
  obraSocialPlan: (data: PlansT[]) =>
    data.map((item) => {
      return { data: item.nombre, id: item.id_plan };
    }),
  professional: (data: ProfessionalT[]) =>
    data.map((item) => {
      return { data: item.nombre, id: item.id_prof };
    }),
  budget: (data: any[]) =>
    data.map((item) => {
      return { data: item.descripcion, id: item.presup };
    }),
  offices: (data: OfficesT[]) =>
    data.map((item) => {
      return { data: item.sucursal, id: item.id_sucursal };
    }),
  prestaciones: (data: PrestPresupT[]) =>
    data.map((item) => {
      return { data: item.descripcion, id: item.id_prestacion };
    }),
  condicionFiscal: (data: any[]) =>
    data.map((item) => {
      return { data: item.text, id: item.id };
    }),
  tipoDocumento: (data: any[]) =>
    data.map((item) => {
      return { data: item.text, id: item.id };
    }),
  condicion: (data: any[]) =>
    data.map((item) => {
      return { data: item.text, id: item.id };
    }),
  sexo: (data: any[]) =>
    data.map((item) => {
      return { data: item.text, id: item.id };
    }),
};

export const automaticCalcualteAge = (birthDate: string) => {
  const [by, bm] = birthDate.split('-');
  let currentAge, currentMonth;

  const d = new Date(),
    month = Number('' + (d.getMonth() + 1)),
    year = Number(d.getFullYear());
  const bmNumber = Number(bm);
  currentAge = year - Number(by);

  if (month < bmNumber) {
    currentAge = currentAge - 1;
    currentMonth = 12 - (bmNumber - month);
  } else if (month === bmNumber) {
    currentMonth = 0;
  } else if (month > bmNumber) {
    currentMonth = month - bmNumber;
  }

  return `${currentAge} años ${currentMonth} meses`;
};

export const isBoolProp = (prop: string) => {
  const boolProps = [
    'es_titular',
    'hepatitis',
    'embarazo',
    'diabetes',
    'alergia',
    'cardiaco',
    'renal',
    'alfa',
  ];
  return boolProps.includes(prop);
};

export const sanitizeGender = (gender: string) => {
  const sanitized: { [key: string]: string } = {
    F: 'Femenino',
    M: 'Masculino',
  };
  return sanitized[gender];
};

export const invalidUser = (error: any) => {
  if (
    error.includes('Usuario Bilog INEXISTENTE') ||
    error.includes('Error de inicio de sesión del usuario') ||
    error.includes('(99999) - Problemas de Conexion')
  ) {
    return true;
  }

  return false;
};

export const cookiesOptions = {
  path: '/',
  maxAge: 86400,
  sameSite: 'lax',
};

export const statMapper = (stats: any[]) => {
  const data = stats.map((s, i) => {
    const { periodo, cantidad } = s;
    const [d, m, y] = periodo.split('/');
    const day = new Date(`${m}/${d}/${y}`).getDay();
    const days = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
    return {
      periodo: days[day],
      cantidad,
    };
  });
  return data;
};

export function addEvent(
  element: any,
  eventName: string,
  callback: () => void,
) {
  if (element.addEventListener) {
    element.addEventListener(eventName, callback, false);
  } else if (element.attachEvent) {
    element.attachEvent('on' + eventName, callback);
  }
}

export const getWeeklySchedules = (schedule: any) => {
  const separated = groupBy(schedule, 'dianumero');

  function getDate(date: string) {
    let [tempD] = date.split('T');
    [, , tempD] = tempD.split('-');
    return tempD;
  }

  const agreggated = days.map((d, i) => {
    if (separated[i + 1]) {
      return {
        schedule: tableScheduleMapper(separated[i + 1]),
        day: d,
        date: getDate(separated[i + 1][0].fecha),
      };
    }
    return null;
  });

  return agreggated.filter((x) => x !== null);
};

export const getProfessionalsSchedules = (schedule: any[]) => {
  let filtered = schedule.filter(
    (s) => s.id_profNavigation.nombre !== 'GUARDIA',
  );
  const separated = groupBy(tableScheduleMapper(filtered), 'id_professional');
  const profsIds = Object.keys(separated);
  let professionals: any[] = profsIds.map((id) => {
    const t = schedule.find((s) => s.id_prof === parseInt(id));

    return {
      name: t.id_profNavigation.nombre,
      id,
    };
  });

  professionals = professionals.map((p) => {
    return {
      schedule: separated[p.id],
      name: p.name,
    };
  });

  professionals = professionals.sort(function (a, b) {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  return professionals;
};

function groupBy(arr: any[], property: string) {
  return arr.reduce(function (memo, x) {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
}

export function parseJson(d: string | number) {
  if (d && typeof d === 'string') return JSON.parse(d);
  return d;
}

export function decodeToken(jwt: string) {
  return jwt_decode(jwt);
}

export function splitOdontogram(odontogram: any) {
  let i,
    j,
    splitted,
    newArr = [],
    chunk = odontogram.length / 4;
  for (i = 0, j = odontogram.length; i < j; i += chunk) {
    splitted = odontogram.slice(i, i + chunk);
    newArr.push(splitted);
  }
  return newArr;
}

export function filterTodosByTooth(todos: any, tooth: any) {
  let newArr: any[] = [];
  todos.forEach((t: any) => {
    if (t.diente_sector?.trim() === tooth) {
      newArr.push(t);
    }
  });
  return newArr;
}

export function checkFileType(source: any) {
  if (source) return source.split('.').pop();
}

export function getSourceFromFileType(fileType: string) {
  const fileToLower = fileType.toLowerCase();
  if (
    fileToLower === 'jpg' ||
    fileToLower === 'jpeg' ||
    fileToLower === 'png' ||
    fileToLower === 'webp'
  )
    return 'IMG';
  if (fileToLower === 'pdf') return 'PDF';
  if (fileToLower === 'txt') return 'TXT';
  if (fileToLower === 'mp4') return 'MP4';
}

export const archivesMapper = (
  archives: any[],
  strings: string[],
  patientId: number,
) =>
  archives.map((archive, index) => {
    const { name, size } = archive.meta;
    let fileType = name.split('.');

    return {
      id_paciente: patientId,
      fecha: new Date().toISOString(),
      descripcion: name,
      filedata: strings[index],
      filetype: `.${fileType[fileType.length - 1]}`,
      size: byteToMb(size),
    };
  });

export const checkMapper = (archives: any[]) =>
  archives.map((archive) => {
    return {
      size: Math.round(archive.size),
    };
  });

export const byteToMb = (bytes: number) => {
  const k = 1024;
  const mb = Math.round(bytes / k / k);
  return mb >= 1 ? mb : 1;
};

export const checkUrl = (url: string) => {
  const reg = new RegExp(/(.*https)(.*cloudinary)/gm);
  return reg.test(url);
};

export const validCreditcard = (cardnumb: string) => {
  const ccErrors = [];
  ccErrors[0] = 'Tipo de tarjeta desconocida';
  ccErrors[1] = 'No se proporcionó un número de tarjeta';
  ccErrors[2] = 'El número de la tarjeta es inválido';
  ccErrors[3] = 'El número de la tarjeta es inválido';
  ccErrors[4] = 'El número de la tarjeta contiene números o dígitos inválidos.';
  const response = (
    success: any,
    message: string | null = null,
    type: string | null = null,
  ) => ({
    message,
    success,
    type,
  });
  const validCardnumb = (numb: string) => {
    const regex = new RegExp('^[0-9]{13,19}$');
    if (!regex.test(numb)) {
      return false;
    }
    return luhnCheck(numb);
  };
  const luhnCheck = (val: string) => {
    let validsum = 0;
    let k = 1;
    for (let l = val.length - 1; l >= 0; l--) {
      let calck = 0;
      calck = Number(val.charAt(l)) * k;
      if (calck > 9) {
        validsum = validsum + 1;
        calck = calck - 10;
      }
      validsum = validsum + calck;
      if (k === 1) {
        k = 2;
      } else {
        k = 1;
      }
    }
    return validsum % 10 === 0;
  };
  const cards = [];
  cards[0] = {
    name: 'Visa',
    length: '13,16',
    prefixes: '4',
    checkdigit: true,
  };
  cards[1] = {
    name: 'MasterCard',
    length: '16',
    prefixes: '51,52,53,54,55',
    checkdigit: true,
  };
  cards[2] = {
    name: 'DinersClub',
    length: '14,16',
    prefixes: '36,38,54,55',
    checkdigit: true,
  };
  cards[3] = {
    name: 'CarteBlanche',
    length: '14',
    prefixes: '300,301,302,303,304,305',
    checkdigit: true,
  };
  cards[4] = {
    name: 'AmEx',
    length: '15',
    prefixes: '34,37',
    checkdigit: true,
  };
  cards[5] = {
    name: 'Discover',
    length: '16',
    prefixes: '6011,622,64,65',
    checkdigit: true,
  };
  cards[6] = {
    name: 'JCB',
    length: '16',
    prefixes: '35',
    checkdigit: true,
  };
  cards[7] = {
    name: 'enRoute',
    length: '15',
    prefixes: '2014,2149',
    checkdigit: true,
  };
  cards[8] = {
    name: 'Solo',
    length: '16,18,19',
    prefixes: '6334,6767',
    checkdigit: true,
  };
  cards[9] = {
    name: 'Switch',
    length: '16,18,19',
    prefixes: '4903,4905,4911,4936,564182,633110,6333,6759',
    checkdigit: true,
  };
  cards[10] = {
    name: 'Maestro',
    length: '12,13,14,15,16,18,19',
    prefixes: '5018,5020,5038,6304,6759,6761,6762,6763',
    checkdigit: true,
  };
  cards[11] = {
    name: 'VisaElectron',
    length: '16',
    prefixes: '4026,417500,4508,4844,4913,4917',
    checkdigit: true,
  };
  cards[12] = {
    name: 'LaserCard',
    length: '16,17,18,19',
    prefixes: '6304,6706,6771,6709',
    checkdigit: true,
  };
  if (cardnumb.length === 0) {
    return response(false, ccErrors[1]);
  }
  cardnumb = cardnumb.replace(/\s/g, '');
  if (!validCardnumb(cardnumb)) {
    return response(false, ccErrors[2]);
  }
  let lengthValid = false;
  let prefixValid = false;
  let cardCompany = '';
  for (let l = 0; l < cards.length; l++) {
    const prefix = cards[l].prefixes.split(',');
    for (let k = 0; k < prefix.length; k++) {
      const exp = new RegExp('^' + prefix[k]);
      if (exp.test(cardnumb)) {
        prefixValid = true;
      }
    }
    if (prefixValid) {
      const lengths = cards[l].length.split(',');
      for (let k = 0; k < lengths.length; k++) {
        if (cardnumb.length === Number(lengths[k])) {
          lengthValid = true;
        }
      }
    }
    if (lengthValid && prefixValid) {
      cardCompany = cards[l].name;
      return response(true, null, cardCompany);
    }
  }
  if (!prefixValid) {
    return response(false, ccErrors[3]);
  }
  if (!lengthValid) {
    return response(false, ccErrors[4]);
  }
  return response(true, null, cardCompany);
};

export const getRegion = (params: any) => {
  let region;
  if (params && params.what_region) {
    if (params.what_region !== 'pry' && params.what_region !== 'arg') {
      region = 'all';
    } else {
      region = params.what_region;
    }
    return region;
  }
  return 'all';
};

export const membershipGridMapper = (data: any[]) =>
  data.map((benefit) => {
    const { fecha, nro_afiliado, habilitado, motivo } = benefit;

    return {
      fecha: new Date(fecha).toLocaleDateString(),
      nro_afiliado,
      habilitado: habilitado === 'S' ? 'Autorizado' : 'No autorizado',
      motivo,
    };
  });

export const tabStyles = (
  selected: any,
  current: any,
  customClass: string = '',
) => {
  return classNames(
    'text-center inline-block not-italic leading-none min-w-[135px] shrink-0 relative cursor-pointer text-slate-400',
    selected === current &&
      'border-b-2 border-b-blue-500 translate-y-px text-slate-800',
    customClass && customClass,
  );
};

export const dataFormater = (valor: string | number) => {
  if (typeof valor === 'string') {
    return Number(valor.replace(/[^0-9.]/g, ''));
  }
};

export const handlerDiscount = (
  discount: any,
  total_value_benefits: number,
) => {
  const { amount, amount_value, percentage_value } = discount;
  if (amount) {
    return amount_value;
  } else {
    return roundNumber((total_value_benefits * percentage_value) / 100);
  }
};

export const budgetSubtotalGenerator = (
  total_value_benefits: number,
  discount: number,
  interest_rate: number,
  iva: number,
) => {
  const data = { importIVA: 0, importInterest: 0, importDiscount: 0 };
  const discountValue = handlerDiscount(discount, total_value_benefits);
  let result = total_value_benefits - discountValue;
  data.importDiscount = discountValue;
  let resultWithInterest = result + (result * interest_rate) / 100;
  data.importInterest = roundNumber(result * (interest_rate / 100));
  let resultWithIVA = resultWithInterest + (resultWithInterest * iva) / 100;
  data.importIVA = roundNumber(resultWithInterest * (iva / 100));

  return { ...data, resultWithIVA };
};

export const calulateImports = (
  amountValue: number,
  discountValue: number,
  discountPercentage: number,
  interest_rate: number,
  iva: number,
) => {
  const calculateDiscount = roundNumber(
    discountValue + (amountValue * discountPercentage) / 100,
  );
  const amountTotalDiscount = amountValue - calculateDiscount;
  const amountTotalInterest =
    amountTotalDiscount + (amountTotalDiscount * interest_rate) / 100;
  const amountInterest = roundNumber(
    amountTotalDiscount * (interest_rate / 100),
  );
  const amountTotalIVA =
    amountTotalInterest + (amountTotalInterest * iva) / 100;
  const amountIVA = roundNumber(amountTotalInterest * (iva / 100));
  const totalAmount = amountTotalInterest + amountIVA;
  return {
    amountTotalInterest,
    amountInterest,
    amountTotalIVA,
    amountIVA,
    totalAmount,
    calculateDiscount,
  };
};

export const checkBalance = (balance: number): any =>
  balance < 0
    ? {
        text: `${Math.abs(balance)} (acreedor)`,
        style: 'text-green-500',
      }
    : balance > 0
    ? {
        text: `${balance} (deudor)`,
        style: 'text-red-500',
      }
    : {
        text: `${balance}`,
        style: 'text-slate-500',
      };
export const tableArticlesMapper = (articles: any[]) =>
  articles.map((article) => {
    const {
      id_concepto,
      concepto,
      id_labo,
      codigo,
      importe,
      habilitado,
      rowversion,
      es_usd,
    } = article;

    return {
      id_concept: id_concepto,
      concept: concepto,
      id_labo: id_labo,
      code: codigo,
      amount: importe,
      enabled: habilitado,
      rowversion: rowversion,
      is_usd: es_usd,
    };
  });

export const tableOrdersMapper = (orders: any[]) =>
  orders.map((order) => {
    const {
      id_pedido,
      pagado,
      descripcion,
      fecha_ped,
      fecha_ent,
      id_laboNavigation: { nombre },
      labo_items,
      rowversion,
      totalAmount,
      es_usd,
    } = order;
    const amount_laboratory = labo_items?.reduce(
      (amount: number, item: any) => amount + item.importe * item.cant,
      0,
    );
    const STATUS = {
      order: {
        text: 'Emitido',
        style: 'bg-slate-50 text-slate-600 border-slate-600/10',
      },
      paid: {
        text: 'Pagado',
        style: 'bg-green-50 text-green-600 border-green-600/10',
      },
      received: {
        text: 'Recibido',
        style: 'bg-yellow-100 !text-yellow-500 border-yellow-600/10',
      },
    } as const;

    return {
      id_order: id_pedido,
      status:
        fecha_ent && pagado
          ? STATUS.paid
          : fecha_ent
          ? STATUS.received
          : STATUS.order,
      description: descripcion,
      date_order: fecha_ped && new Date(fecha_ped).toLocaleDateString(),
      date_received: fecha_ent && new Date(fecha_ent).toLocaleDateString(),
      amount_total: numberToCurrency(totalAmount),
      name_laboratory: nombre,
      amount_laboratory: numberToCurrency(amount_laboratory),
      rowversion: rowversion,
      concepts: labo_items,
      is_usd: es_usd,
    };
  });

export const tableConceptsMapper = (concepts: any[]) =>
  concepts.map((concept) => {
    const {
      code,
      description,
      quantity,
      tooth_section,
      color,
      amount,
      id_order_concept,
      total_amount_order_quantity,
    } = concept;

    return {
      code,
      concept: description,
      quantity,
      tooth_section,
      color,
      individual_amount: `$${amount}`,
      total_amount: `$${total_amount_order_quantity}`,
      id_order_concept,
    };
  });
export const tableMedicinesMapper = (medicines: any[]) =>
  medicines.map((medicine) => {
    const {
      productName,
      presentation,
      quantity,
      diagnosis,
      dosage,
      prolonged_treatment,
      id_medicine_recipe,
      drugName,
      observations,
    } = medicine;

    return {
      name: productName,
      diagnosis: diagnosis?.description,
      quantity,
      dosage,
      prolonged_treatment,
      id_medicine_recipe,
      presentation,
      drugName,
      observations,
    };
  });
export const validationCanUseUsd = ({
  params,
  user,
}: {
  [key: string]: boolean | null;
}) => !!(params && user);

export const bannersMapper = (banners: any[]) =>
  banners.map((banner) => {
    const {
      bannerId,
      imageData,
      startDate,
      endDate,
      mobileApp,
      droidDeepLink,
      iosDeepLink,
      webUrl,
      message,
      type,
      device,
      plans,
      isDevelop,
      formImageData,
      applicationPlans,
    } = banner;

    return {
      id: bannerId,
      image_data: imageData,
      start_date: startDate,
      end_date: endDate,
      mobile_app: mobileApp,
      droid_deep_link: droidDeepLink,
      ios_deep_link: iosDeepLink,
      url: webUrl,
      message,
      type,
      device,
      plans,
      is_develop: isDevelop,
      form_image_data: formImageData,
      application_plans: applicationPlans,
    };
  });

export const SplitNameFormatter = (
  nameParts: string[],
): { firstName: string; lastName: string } => {
  let firstName = '';
  let lastName = '';

  if (nameParts.length >= 3) {
    lastName = nameParts.slice(0, -1).join(' ');
    firstName = nameParts[nameParts.length - 1];
  } else if (nameParts.length === 2) {
    lastName = nameParts[0];
    firstName = nameParts[1];
  } else {
    lastName = nameParts[0];
    firstName = '.';
  }
  return { firstName, lastName };
};

export const filteredProf = ({
  professionals,
  offices,
}: {
  professionals: ProfessionalT[];
  offices: OfficesT[];
}) => {
  const profs = professionals.filter((p: any) => {
    if (offices?.[0]?.id_sucursal) {
      const porfessionalsByOffice = p.prof_horarios.some(
        (prof: { [key: string]: any }) =>
          prof.id_sucursalNavigation?.id_sucursal === offices?.[0]?.id_sucursal,
      );
      return p.habilitado && porfessionalsByOffice;
    }
    return p.habilitado;
  });
  return profs;
};

export const hardRefresh = () => {
  const timestamp = new Date().getTime();
  window.location.href = window.location.href.split('?')[0] + '?_=' + timestamp;
};

export const formatRecipeDate = (date: Date) => {
  if (isEqual(date.getDate(), new Date().getDate())) {
    return addHours(new Date(), 3);
  }
  const utc_date = new UTCDate(date);
  return addHours(new Date(utc_date.toDateString()), 3);
};

export const formatPhoneNumberWhatsapp = (
  phoneNumber: string,
  country: string,
) => {
  if (!phoneNumber || typeof phoneNumber !== 'string') {
    return phoneNumber;
  }
  let number = phoneNumber.trim();
  if (number.startsWith('0')) {
    number = number.slice(1);
  }
  const config =
    PHONE_CONFIG[country?.toLowerCase().trim()] || PHONE_CONFIG.default;

  if (config.adjustments) {
    number = config.adjustments(number);
  }
  if (!number.startsWith(config.prefix)) {
    number = `${config.prefix}${number}`;
  }

  return (config.regex.test(number) ? number : phoneNumber).replace(/\D/g, '');
};
